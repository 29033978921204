import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  useNavigate,
  BrowserRouter,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppBar, Box, Button, Container, CssBaseline, ThemeProvider, Toolbar, Typography, createTheme } from "@mui/material";
import Main from "./pages/main";

import { GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import Login from "./pages/login";
import { useAuthState } from "react-firebase-hooks/auth";
import LoginPage from "./LoginPage";
import SignUp from "./SignUpPage";

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { Helmet } from "react-helmet";



const theme = createTheme();

const firebaseConfig = {
  apiKey: "AIzaSyDkkZa3b-wBO9Y6aLwylJpZEp_ByyPp4pc",
  authDomain: "striking-splice-353411.firebaseapp.com",
  projectId: "striking-splice-353411",
  storageBucket: "striking-splice-353411.appspot.com",
  messagingSenderId: "501058354129",
  appId: "1:501058354129:web:6ac1056026c27738357ef0",
  measurementId: "G-0MCLES0JXL"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

/*
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />
  },
  {
    path: "about",
    element: <div>About</div>,
  },
  {
    path: "login",
    element: <Login auth={auth} />
  }
]);
*/


const Layout = ({ children }) => {
  const [user, loading, error] = useAuthState(auth)

  let authStatus = 'ladataan...'

  if (user)
    authStatus = 'kirjautunut sisään'

  if (!user && !loading && !error)
    authStatus = 'kirjautunut ulos'

  if (loading)
    authStatus = 'ladataan...'

  if (error)
    authStatus = 'virhe sisäänkirjautumisessa'

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
            <title>Massit</title>
      </Helmet>
      <CssBaseline />

      <AppBar position="relative">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>

          <Typography variant='h6' color="inherit" noWrap>
            Massit
          </Typography>

          <Typography variant="p">{authStatus}</Typography>



          </Box>


          {user ? <Button sx={{ color: 'white' }} onClick={() => {
            signOut(auth)          
          }}>Kirjaudu ulos</Button> : null}




        </Toolbar>

      </AppBar>
      <Container>
          <Outlet />

      </Container>
    </ThemeProvider>
  )
}

const LoginHandler = () => {
  const Navigate = useNavigate()
  const [user, loading, error] = useAuthState(auth)

  if (!user && !loading) {
    Navigate('/auth/login')
  }
  



  return <Layout />
}

const Auth = () => {
  const Navigate = useNavigate()

  Navigate('/auth/login')
  
}

const Router = () => (
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<LoginHandler />}>
          <Route path="/" element={<Main auth={auth} />} />
        </Route>
        <Route path='auth'>
          <Route index element={<Auth />} />
          <Route path='login' element={<LoginPage auth={auth} defaultTheme={theme} />} />
          <Route path='signup' element={<SignUp auth={auth} defaultTheme={theme} />} />
        </Route>
    </Routes>
  </BrowserRouter>
)

createRoot(document.getElementById("root")).render(<Router />)
