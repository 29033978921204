import { Button, Typography } from "@mui/material"
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

const Main = ({ signFunc, auth }) => {

    const [user] = useAuthState(auth)
 

    return (
        <>
            <Typography variant='h2' component='h1' sx={{ mt: 2 }}>
                Ainutlaatuiset Cashback-edut
            </Typography>

            {
                user ? <p>Ei saatavilla vielä ajankohtaisia tarjouksia. Pysy kuulolla!</p> : null
            }
            
        </>
    )
}

export default Main