import { Button, Typography } from "@mui/material"
import { useSignInWithGoogle, useAuthState, useSignInWithEmailAndPassword } from "react-firebase-hooks/auth"
import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const Login = ({auth }) => {
    const Navigate = useNavigate()

    const [authenticatedUser, authLoading, authError] = useAuthState(auth)
    if (authenticatedUser) {
        Navigate('/')
    }

    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    

    if (user) {
        console.log(user)
        return <p>Welcome, {user.toString()}</p>
    }
    if (loading)
        return <p>Loading...</p>

    if (error)
        return <p>error</p>

    return (
        
        <>
            <Typography variant='h2' element='h1' sx={{ mt: 2, mb: 2 }}>Kirjaudu sisään</Typography>
            <Button onClick={() => signInWithGoogle()} variant='contained'>Kirjaudu Googlen avulla</Button>
        </>
    )
}

export default Login