import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {  ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuthState, useSignInWithGoogle, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Alert } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const handleSubmit = (e) => e.preventDefault()

const LoginPage = ({ defaultTheme, auth }) => {

    const [email, setEmail] = React.useState('')
    const [password, setPass] = React.useState('')

    const Navigate = useNavigate()

    const [signInWithEmail, user, loading, error] = useSignInWithEmailAndPassword(auth)

    const [authenticatedUser, authLoading, authError] = useAuthState(auth)
    if (authenticatedUser) {
        Navigate('/')
    }




    return (
        <ThemeProvider theme={defaultTheme}>
        <Helmet>
            <title>Massit - Kirjaudu</title>
        </Helmet>
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
                backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
                sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3
                }}
            >
                <Typography component="h1" variant="h5">
                    Kirjaudu sisään
                </Typography>
                {
                    error ? <Alert sx={{ mt: 2 }} severity='error'>Virheellinen sähköpostiosoite tai salasana</Alert> : null
                }
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Sähköpostiosoite"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Salasana"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPass(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3,}}
                    onClick={() => {
                        setEmail('')
                        setPass('')
                        signInWithEmail(email, password)
                    }}
                >
                    Kirjaudu sisään
                </Button>

                <GoogleSignIn auth={auth} />

                </Box>



                <Grid container>
                    <Grid item xs>
                    <Link href="#" variant="body2">
                        Unohditko salasanasi?
                    </Link>
                    </Grid>
                    <Grid item>
                    <RouterLink to='/auth/signup'><Link variant="body2">
                        {"Eikö sinulla ole vielä tiliä? Rekisteröidy tästä"}
                    </Link>
                    </RouterLink>
                    </Grid>
                </Grid>
                </Box>
            </Grid>
        </Grid>
        </ThemeProvider>
    )
}

const GoogleSignIn = ({auth}) => {
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    
    return (
        <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        sx={{ mt: 1, mb: 2 }}
        onClick={() => signInWithGoogle()}
    >
        Jatka käyttäen Googlea
    </Button>

    )
    
}



export default LoginPage